.DayPicker {
  border-radius: 10px !important;
}

.form-date {
  .DateRangePickerInput,
  .SingleDatePickerInput {
    background-color: transparent !important;
    margin-top: 0px;

    .DateInput {
      background: transparent !important;
      .DateInput_input {
        background-color: transparent !important;
        @include fonts(grey, $fontAvenirBook, 17);
      }
    }
  }
}

.DateRangePickerInput,
.SingleDatePickerInput {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
  margin-left: 20px !important;
  margin-top: 7px;
  @include fonts(grey, $fontAvenirBook, 18);
  .DateInput {
    background: transparent !important;
    width: 100px;
    .DateInput_input {
      background-color: transparent !important;
      padding: 5px 11px 3px !important;
      font-size: rem(18) !important;
    }
    .DateInput_fang {
      top: 49px !important;
    }
  }
}

.CalendarDay__default {
  border: none !important;
  @include fonts(#707070, $fontAvenirBook, 15);
  color: #707070 !important;
}

.CalendarDay__blocked_out_of_range {
  border: none !important;
  @include fonts(#a8a7a7, $fontAvenirBook, 15);
  color: #cacaca !important;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $colorBlue !important; //background
  opacity: 0.4;
  color: white !important; //text
  border: none !important; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $colorBlue !important;
  background-color: red;
  color: white !important; //text
  border: none !important;
  border-radius: 50%;
  &.CalendarDay__selected_start {
    border-radius: 50% 0 0 50%;
  }
  &.CalendarDay__selected_end {
    border-radius: 0 50% 50% 0;
  }
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $colorBlue10 !important;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $colorBlue !important;
  opacity: 0.4;
  color: white !important;
}
