
@font-face {
  font-family: 'Spartan-Black';
  src: url('../../fonts/Spartan-Black.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-Black.woff') format('woff'),
    url('../../fonts/Spartan-Black.ttf')  format('truetype'),
    url('../../fonts/Spartan-Black.svg#Spartan-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-Bold';
  src: url('../../fonts/Spartan-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-Bold.woff') format('woff'),
    url('../../fonts/Spartan-Bold.ttf')  format('truetype'),
    url('../../fonts/Spartan-Bold.svg#Spartan-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-ExtraBold';
  src: url('../../fonts/Spartan-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-ExtraBold.woff') format('woff'),
    url('../../fonts/Spartan-ExtraBold.ttf')  format('truetype'),
    url('../../fonts/Spartan-ExtraBold.svg#Spartan-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-ExtraLight';
  src: url('../../fonts/Spartan-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-ExtraLight.woff') format('woff'),
    url('../../fonts/Spartan-ExtraLight.ttf')  format('truetype'),
    url('../../fonts/Spartan-ExtraLight.svg#Spartan-ExtraLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-Light';
  src: url('../../fonts/Spartan-Light.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-Light.woff') format('woff'),
    url('../../fonts/Spartan-Light.ttf')  format('truetype'),
    url('../../fonts/Spartan-Light.svg#Spartan-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-Medium';
  src: url('../../fonts/Spartan-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-Medium.woff') format('woff'),
    url('../../fonts/Spartan-Medium.ttf')  format('truetype'),
    url('../../fonts/Spartan-Medium.svg#Spartan-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-Regular';
  src: url('../../fonts/Spartan-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-Regular.woff') format('woff'),
    url('../../fonts/Spartan-Regular.ttf')  format('truetype'),
    url('../../fonts/Spartan-Regular.svg#Spartan-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-SemiBold';
  src: url('../../fonts/Spartan-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-SemiBold.woff') format('woff'),
    url('../../fonts/Spartan-SemiBold.ttf')  format('truetype'),
    url('../../fonts/Spartan-SemiBold.svg#Spartan-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Spartan-Thin';
  src: url('../../fonts/Spartan-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Spartan-Thin.woff') format('woff'),
    url('../../fonts/Spartan-Thin.ttf')  format('truetype'),
    url('../../fonts/Spartan-Thin.svg#Spartan-Thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Black';
  src: url('../../fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Avenir-Black.otf')  format('opentype'),
    url('../../fonts/Avenir-Black.woff') format('woff'),
    url('../../fonts/Avenir-Black.ttf')  format('truetype'),
    url('../../fonts/Avenir-Black.svg#Avenir-Black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url('../../fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Book.otf')  format('opentype'),
    url('../../fonts/AvenirLTStd-Book.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Book.ttf')  format('truetype'),
    url('../../fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Medium';
  src: url('../../fonts/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Medium.otf')  format('opentype'),
    url('../../fonts/AvenirLTStd-Medium.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Medium.ttf')  format('truetype'),
    url('../../fonts/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url('../../fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/AvenirLTStd-Roman.otf')  format('opentype'),
    url('../../fonts/AvenirLTStd-Roman.woff') format('woff'),
    url('../../fonts/AvenirLTStd-Roman.ttf')  format('truetype'),
    url('../../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?bj0see');
  src: url('../../fonts/icomoon.eot?bj0see#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?bj0see') format('truetype'),
    url('../../fonts/icomoon.woff?bj0see') format('woff'),
    url('../../fonts/icomoon.svg?bj0see#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
