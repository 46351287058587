.change-password, .reset-password {
  &__form {
    width: 100%;
    padding: rem(50);
  }

  &__title {
    @include fonts($colorGreyLetter, $fontAvenirRoman, 18, 26);
    letter-spacing: rem(1.8);
    text-transform: uppercase;
    margin-bottom: rem(50);
  }

  &__group {
    position: relative;
    display: flex;
    flex-direction: column;
    width: rem(360);
    height: rem(75);
    margin-bottom: rem(44);
  }

  &__label {
    height: rem(16);
    @include fonts($colorGreyLetter, $fontAvenirRoman, 12, 33);
    letter-spacing: rem(1.2);
    text-transform: uppercase;
    margin-bottom: rem(8.8);
  }

  &__input {
    height: rem(50);
    width: rem(360);
    border: rem(1) solid #7e7e7e;
    border-radius: rem(5);
    background: transparent;
    @include fonts($colorGreyLetter, $fontAvenirRoman, 14, 16);
    padding: rem(17);

    &:hover {
      border: rem(1) solid $colorGreyLetter;
      @include fonts($colorGreyLetter, $fontAvenirRoman, 14, 16);
    }
    &:focus,
    &:target {
      border-color: $colorBlue;
    }

    &::placeholder {
      color: #7e7e7e;
    }
  }

  &__icons {
    background-color: transparent;
    border: none;
    color: black;
    font-size: rem(16);
    position: absolute;
    right: rem(16.69);
    top: rem(40);
  }

  &__button {
    height: rem(48);
    width: rem(360);
    background-color: #3C3C3C !important;
    margin-top: rem(22);

    @include fonts(#fff, $fontAvenirRoman, 12, 19, 900);
    text-transform: uppercase;

    &:hover {
      background: $colorBlue;
    }
  }
}
