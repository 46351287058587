.icon-block {
  position: absolute;
  top: 2px;
  left: -3px;
  height: 15px;
  width: 15px;
  color: #d31e1e;
}

.blue__text {
  color: $colorBlue;
}

.grey__text {
  color: $colorGreyLetter;
}

.red__text {
  color: $colorRed;
}

.icon-arrow-down,
.icon-arrow-up {
  @include fonts(black, $fontAvenirBook, 14);
  vertical-align: middle;
  margin-left: 5px;
  // font-size: rem(10);
}

.main--loader-dual-ring {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  position: relative;
  &.min-height {
    height: 20vh;
  }
  &::before {
    content: ' ';
    position: absolute;
    display: inline-block;
    width: 150px;
    height: 150px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #003060;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    animation: lds-dual-ring 1.5s linear infinite;
  }
  &:after {
    content: ' ';
    position: absolute;
    display: inline-block;
    width: 150px;
    height: 150px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #01aced;
    border-bottom-color: transparent;
    border-left-color: transparent;
    animation: lds-dual-ring-back 1.5s linear infinite;
  }
}
.main--loader-children {
  position: absolute;
  top: 60px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(495deg);
  }
}

@keyframes lds-dual-ring-back {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(-225deg);
  }
}

.caret {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 5px dashed;
  display: inline-block;
  height: 0;
  margin-left: 8px;
  vertical-align: middle;
  width: 0;
}

.schedule__holiday-container {
  font-family: $fontAvenirBook;
  padding-bottom: 20px;
  .schedule__standard-title {
    @include fonts(white, $fontAvenirBook, 15);
    line-height: rem(16);
    position: relative;
    background-color: $colorBlue;
    border-radius: 20px;
    width: fit-content;
    margin: auto;
    padding: 9px 50px 6px;
    text-transform: uppercase;
    top: -10px;
  }

  .schedule__standard-sessions {
    margin: auto;
    text-align: center;
  }

  .schedule__standard-sessions-container {
    margin: 30px 10px 15px;
    .schedule__standard-session-container {
      margin: auto;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding-bottom: 10px;
      .schedule__standard-session-title {
        background-color: #f2f2f2;
        position: relative;
        top: -10px;
        width: fit-content;
        padding: 0 20px;
        text-align: center;
        margin: auto;
      }
    }
  }
}

.schedule__standard-container {
  background-color: #f2f2f2;
  font-family: $fontAvenirBook;
  padding-bottom: 20px;
  .schedule__standard-title {
    @include fonts(white, $fontAvenirBook, 15);
    line-height: rem(16);
    position: relative;
    background-color: $colorBlue;
    border-radius: 20px;
    width: fit-content;
    margin: auto;
    padding: 9px 50px 6px;
    text-transform: uppercase;
    top: -10px;
  }

  .schedule__standard-sessions {
    margin: auto;
    text-align: center;
  }

  .schedule__standard-sessions-container {
    margin: 30px 10px 15px;
    .schedule__standard-session-container {
      margin: auto;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding-bottom: 10px;
      .schedule__standard-session-title {
        background-color: #f2f2f2;
        position: relative;
        top: -10px;
        width: fit-content;
        padding: 0 20px;
        text-align: center;
        margin: auto;
      }
    }
  }
}

.schedule_selector {
  background-color: white;
  display: inline;
  justify-content: center;
  padding: 5px 2px;
  border: 2px solid black;
  margin: 0 10px;
  button {
    &:focus {
      outline: none;
    }
    font-weight: 400;
    border: none;
    background-color: transparent;
  }
  .schedule_button-counter {
    display: inline;
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 5px 10px;
  }
}

.checkmark-container {
  position: relative;
  label {
    top: 2px;
    margin-left: 25px;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0px;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1px solid grey;
    // margin: 0;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 0px;
      width: 5px;
      height: 10px;
      border: solid $colorBlue;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  input[type='checkbox']:checked ~ .checkmark:after {
    display: block;
  }
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 2px;
    height: 15px;
    width: 15px;
    left: 0px;
    z-index: 1;
  }
}

.modal--message {
  // .modal--message__large {
  max-width: 80vw;
  // }

  .modal-content {
    background-color: $colorGrey;
    border: none !important;
    border-radius: 10px;
    max-height: 600px;
    overflow-y: scroll;

    &.modal-message__transparent {
      background-color: transparent;
    }

    &.modal-message__error {
      background-color: $colorRed;
      border-color: $colorRed;
    }
  }

  .modal-body {
    padding: 30px 50px;
    position: relative;
    padding-top: 30px;
    .close-modal {
      position: absolute;
      top: 0px;
      left: 94%;
      font-weight: 500;
    }
    h2 {
      @include fonts($colorBlue, $fontAvenirMedium, 15);
      text-align: center;
      line-height: rem(22);
      margin-bottom: 20px;
      letter-spacing: 4px;
    }

    p {
      @include fonts($colorBlue, $fontAvenirMedium, 17);
      line-height: rem(19);
      margin-bottom: 5px;
    }
  }

  .modal-footer {
    border-top: none;
  }
}

.btn-sweat-edit {
  padding: 0;
  margin: 0;
  &:focus {
    outline: 0;
  }
}

.btn-sweat-download {
  color: $colorBlue;
  border: none;
  padding: 0;
  margin: 0;
  &--bordered{
    padding: 2px;
    border: 1px solid $darkGray;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

  }
  &:focus {
    outline: 0;
    color: $colorBlue10;
  }
  &:hover {
    color: $colorBlue10;
  }
}

.user--form {
  .user--edit-row {
    border: 1px solid $colorBlue;
    margin: 0 20px;
    padding: 25px 20px 13px;
    position: relative;
    .user--edit-title {
      background-color: #f5f5f5;
      position: absolute;
      top: -10px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      padding: 0 25px;
    }
    label {
      @include fonts(grey, $fontAvenirBook, 15);
      line-height: rem(10);
    }
    .user--input {
      @include fonts(black, $fontAvenirBook, 15);
      line-height: rem(20);
      border-radius: 0;
      padding: 10px 10px 5px;
      height: fit-content;
    }
    .checkbox__input-label {
      background-color: transparent;
      border: none;
      display: inline;
      position: relative;
      top: -11px;
      left: 19px;
      color: grey;
    }
    .checkmark-container {
      label {
        padding-top: 6px;
        @include fonts(grey, $fontAvenirBook, 15);
        line-height: rem(22);
      }
      input {
        top: 7px;
      }
      .checkmark {
        top: 7px;
      }
    }
  }
  .btn-sweat {
    // border-radius: 20px;
    width: 100% !important;
  }
  hr {
    background-color: $colorBlue;
    border-color: $colorBlue;
  }
}

.DayPickerInput {
  input {
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 0;
    @include fonts(grey, $fontAvenirBook, 18);
    display: inline;
    margin-top: 8px;
    margin-left: 1rem !important;
    padding-bottom: 6px;
    padding-left: 10px;
    width: 160px;
    background-color: transparent;
  }
}
.DayPickerInput-Overlay {
  margin-top: 15px;
  box-shadow: none !important;
  .DayPicker {
    width: 100%;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.4);

    .DayPicker-Month {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    .DayPicker-Caption {
      @include fonts(grey, $fontAvenirBook, 19);
      line-height: rem(20);
    }

    .DayPicker-NavButton {
      color: grey;
    }

    .DayPicker-Weekday {
      @include fonts($colorBlue10, $fontAvenirBook, 19);
      line-height: rem(20);
    }

    .DayPicker-Day {
      @include fonts(grey, $fontAvenirBook, 17);
      // line-height: rem(18);

      &.DayPicker-Day--disabled {
        color: #7a7a7a;

        &:hover {
          color: #7a7a7a;
        }
      }

      &.DayPicker-Day--selected {
        background-color: transparent !important;
        &:before {
          // background-color: transparent;
          content: ' ';
          position: absolute;
          background-clip: content-box;
          background-color: $colorBlue10;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          top: 8%;
          z-index: -1;
        }
      }

      &:hover {
        background-color: transparent !important;
        color: $colorBlue;
      }
    }
  }
}

.main__dashboard-calendar {
  color: black;
  background-color: transparent;
  width: fit-content;
  border: none;
  &:hover,
  &:focus {
    color: $colorBlue;
  }
}

.main__sku-package-item-edit {
  color: black;
  background-color: transparent;
  width: fit-content;
  border: none;
  &:hover,
  &:focus {
    color: $colorBlue;
  }
}

.icon-calendar.date-picker {
  position: relative;
  top: 5px;
  &:hover {
    cursor: pointer;
  }
}

.btn-sweat {
  @include fonts($colorBlue, $fontAvenirBook, 15);
  line-height: rem(25);
  border-radius: 20px;
  border: $colorBlue 1px solid;
  padding: 5px 7px 3px !important;
  &:hover,
  &:focus:hover {
    background-color: $colorBlue !important;
    border: $colorBlue 1px solid;
  }
  &:focus {
    border: $colorBlue 1px solid;
    background-color: transparent !important;
  }
  &:disabled {
    color: $colorGreyLetter;
  }
  &:disabled:hover {
    color: white;
    background-color: $colorGreyLetter !important;
  }
  &.fill {
    background-color: $colorBlue !important;
    color: white;
    &:hover,
    &:focus:hover {
      background-color: transparent !important;
      border: $colorBlue 1px solid;
      color: $colorBlue;
    }
    &:focus {
      border: $colorBlue 1px solid;
      background-color: transparent !important;
      color: $colorBlue;
    }
    &:disabled {
      color: $colorGreyLetter;
    }
    &:disabled:hover {
      color: white;
      background-color: $colorGreyLetter !important;
    }
  }
}

button {
  background-color: transparent !important;
  border: none;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type='number'] {
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.packages--items-container {
  border: 1px solid $colorBlue;
  border-radius: 10px;
  padding: 10px;
  .table.package--items-table {
    th {
      text-align: center;
    }
    .main--form_package-items-tr {
      td {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        .main--form__invisible-input {
          @include fonts($colorGreyLetter, $fontAvenirBook, 15);
        }
        .button-like {
          cursor: pointer;
        }
      }
      &.main--form_is-edit {
        td {
          .main--form__invisible-input {
            background-color: white;
            border: 1px solid $colorBlue10 !important;
            border-radius: 20px;
            @include fonts($colorGreyLetter, $fontAvenirBook, 15);
            padding: 4px;
          }
        }
      }
    }
  }
}

.add-item--separator {
  @include fonts($colorBlue, $fontAvenirBook, 17);
  &::after {
    content: '';
    display: inline-block;
    margin-left: 8px;
    width: 85%;
    height: 1px;
    background-color: $colorBlue;
    position: relative;
    top: -5px;
  }
}

.labeled-value-information {
  text-align: center;
  &__value {
    display: flex;
    font-size: 55px;
    line-height: 55px;
    color: $colorGreyLetter;
    align-items: center;
    margin-bottom: 15px;
    justify-content: center;
    & p {
      margin-bottom: 0;
    }
    & img {
      margin: 0 10px;
    }
    &-title {
      font-size: 16px;
      color: $darkGray;
      margin-bottom: 18px;
    }
  }
  & .badge-pill {
    padding: 12px 24px;
    font-weight: 400;
    font-size: 14px;
    min-width: 176px;
  }
}
.btn {
  color: $sweatBlue;
  & .btn-icon {
    font-size: inherit;
    color: $sweatBlue;
    margin-right: 3px;
    padding-top: 2px;
  }
  &-transparent {
    background-color: transparent;
    border: none;
    color: $colorGreyLetter;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;

    &:hover {
      background-color: $softBlue !important;
    }
    &--absolute {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}

.badge-pill {
  color: $colorBlack;
  padding: 6px 8px;
  &--blue {
    background-color: $softBlue3;
    color: $darkBlue;
  }
  &--dark-red {
    background-color: $colorRed10;
    color: #fff;
  }
  &--red {
    background-color: $colorlightSalmon;
    color: $colorRed10;
  }
  &--dark-blue {
    background-color: $sweatBlue;
  }
  &--ligth-green {
    background-color: $colorLightGreen;
  }
  &--ligth-blue {
    background-color: $softBlue3;
  }
  &--ligth-blue2 {
    background-color: $softBlue2;
  }
  &--font-dark-blue{
    color: $darkBlue2;
    font-weight: 500;
  }
  & i {
    font-size: 10px;
  }
  &__outlined {
    background-color: transparent;
    border: 1px solid $darkGray;
    color: $darkGray;
    &--blue {
      border-color: $sweatBlue;
    }
    &--red {
      border-color: $colorSalmon;
    }
  }
}
.colorized-list {
  color: $darkGray;
  font-size: 14px;
  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 14px;
    margin-bottom: 25px;
  }
  & span {
    font-weight: 600;
  }
  &--red {
    & span {
      color: $colorSalmon;
    }
    & .colorized-list__title {
      color: $colorSalmon;
    }
  }
  &--blue {
    & span {
      color: $sweatBlue;
    }
    & .colorized-list__title {
      color: $sweatBlue;
    }
  }
}
.ranking-table {
  width: 100%;
  font-size: 14px;
  color: $darkGray;
  & th,
  td {
    text-align: center;
    padding-bottom: 10px;
    &:first-child {
      text-align: left;
    }
  }
  &__label {
    color: $darkGray;
    font-weight: 600;
    margin-right: 5px;
  }
}

.information-card {
  margin-bottom: 20px;
  & .badge-pill {
    padding: 8px 16px;
  }
  &__header {
    position: relative;
    padding-bottom: 35px;
    border-bottom: 0.5px solid $darkGray;
    &-title {
      font-size: 24px;
      color: $darkGray;
      font-weight: 400;
      margin-bottom: 0;
    }
    &-icon {
      position: absolute;
      font-weight: 400;
      font-size: 24px;
      color: $darkGray;
      top: -3px;
      right: 0;
    }
    & .badge-pill {
      margin-top: 17px;
      color: #fff;
    }
  }
  &__body {
    padding-top: 35px;
  }
  &__growth-data {
    & .labeled-value-information {
      text-align: left;
      &__value {
        justify-content: start;
        font-size: 45px;
        margin-bottom: 10px;
        &-title {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
    }
    &--secondary {
      & .labeled-value-information {
        &__value {
          font-size: 35px;
        }
      }
    }
  }
}

.form-control {
  background-color: transparent !important;
}

.input-general{
  position: relative;
  &__label{
    position: absolute;
    font-size: 14px;
    margin: 0;
    top: -12px;
    left: 10px;
    transform: translateY(-14);
    background-color:#f2f7fb ;
    color: $darkGray;
  }
  & input::placeholder{
    color: $darkGray;
    font-size: 12px;
  }
  &__datepicker{
    background-color: $softGray2;
    border-radius: 5px;
  }
}
.month-picker__input{
  background-color: $softGray2 !important;
  padding:0 15px;
  width: 260px;
  height: 36px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-labels{
    display: flex;
    flex: 1;
    & span{
      text-transform: uppercase;
      font-size: 16px;
      color: $darkGray;
      width: 50%;
      &:first-of-type{
        border-right: 1px solid $softGray5;
      }
    }
  }
  & i {
    color: $darkGray;
  }
}


.table-buttons-container{
  display: flex;
  align-items: center;
  gap: 10px;
}

.general-table-list{
  border: none;
  & th,td{
    border: none !important;
  }
  & thead{
    &  th{
      padding:20px  5px ;
      color: $darkGray;
      font-size: 500;
      border-bottom: 1px solid $darkGray !important;
    }
  }
  & tbody td{
    color: $darkGray;
    font-size: 500;
    border-bottom: 1px solid $softGray5 !important;
    padding: 30px 5px 20px;
  }
}

.react-select{
  &__indicator-separator{
    display: none;
  }
  &__input-container{
    color: $darkGray !important;
  }
  &__indicators{
    & i{
      color: $darkGray;
    }
  }

}

.location-date-rage-filter{
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
  &__button{
    padding: 6px 12px !important;
    margin-left: 20px;
  }
  &__label{
    font-size: 14px;
    color: $darkGray;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
  &__title {
    font-size: 23px;
    color: $darkGray;
    font-weight: 400;
    margin: 0;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  & .datepicker-button {
    height: 36px;
  }
  & .react-select {
    &__control {
      background-color: $softGray2 !important;
      border: none !important;
      border-radius: 5px;
      &:focus, &:hover {
        border: none !important;
      }
      & .location-date-rage-filter__select-period{
        background-color: transparent;
      }
    }
    &__indicators {
      margin-right: 15px;
    }
    &__indicator-separator,
    &__clear-indicator,
    &__multi-value {
      display: none;
    }
  }

  &__select-period {
    width: 150px;
    margin-right: 10px;
    & .react-select__indicator-separator {
      display: none;
    }
    & .react-select__control {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

}
.button{
  &__blue{
    padding: 16px;
    background-color: $sweatBlue !important;
    color: #fff !important;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
    &--light{
      background-color: $softBlue2 !important;
      color: $darkBlue !important;
    }
    & img{
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  &__danger{
    padding: 16px ;
    background-color: $colorRed !important;
    color: #fff !important;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__nav{
    color: $sweatBlue !important;
    border: none;
    font-size: 14px;
    font-weight: 600;
    &:hover{
     color: $sweatBlue !important;
    }
  }
}

.general-modal{
  &__body{
    padding: 45px;
  }
  &__information{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
    & h2{
      font-size: 16px;
      color: $softGray;
      font-weight: 600;
      margin-bottom: 15px;
    }
    & p{
      font-size: 16px;
      color: $softGray;
    }
  }
  &__buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    & button{
      min-width: 30%;
    }

  }
  &__list{
    margin-bottom: 30px;
    & li{
      font-size: 14px;
      color: $softGray;
      margin-bottom: 10px;
    }
  }
}
