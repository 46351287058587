.main__dashboard-search-button {
  background-color: transparent;
  border-radius: 30px;
  border: $colorBlue 1px solid;
  @include fonts($colorBlue, $fontAvenirBook, 15);
  line-height: rem(25);
  margin-top: 5px;
  padding: 5px 7px 3px !important;
  width: 150px;

  &:hover {
    background-color: $colorBlue !important;
    border: $colorBlue 1px solid;
    color: white;
  }

  .spinner-border {
    border-width: 2px;
  }
}

.main--dashboard-no-items {
  @include fonts($colorBlue, $fontAvenirBook, 20);
  text-align: center;
}

.dashboard {
  &__title {
    @include fonts($colorGreyLetter, $fontAvenirBook, 24);
    font-weight: 500;
    margin-bottom: 20px;

    &--ligth-green {
      color: $colordarkGreen;
    }

    &--ligth-blue {
      color: $sweatBlue;
    }

    &--dark-blue {
      color: $darkBlue;
    }
  }

  &__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(400px, 1fr));
    grid-template-rows: auto auto 250px;
    gap: 20px;
    padding-bottom: 20px;

    &--graph {
      &-2cols {
        grid-template-columns: repeat(2, minmax(350px, 1fr));
        grid-template-rows: auto 250px;
        grid-template-areas: 'main main' 'information information';
      }

      &-3cols {
        grid-template-columns: repeat(3, minmax(330px, 1fr));
        grid-template-rows: auto auto;
        grid-template-areas: 'main main main' 'information information information';
      }
    }
  }

  &__item {
    padding: 30px 40px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    &:nth-last-child(2),
    &:nth-last-child(1) {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 2px;
        background-color: $softGray3;
      }

      /* Styles for the scrollbar thumb */
      &::-webkit-scrollbar-thumb {
        background-color: $darkGray;
      }
    }

    & .main--loader-dual-ring {
      height: 300px;
    }

    &-container {
      &--centered {
        display: grid;
        justify-items: center;
        align-items: end;
      }

      &--2col {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-template-rows: 1fr;
        gap: 30px;
      }
    }

    &-graph-main {
      grid-area: main;
    }
  }

  &__body {
    width: 100%;
    min-height: 700px;
  }

  &__graph {
    &-container {
      height: 350px;
      width: 100%;
    }

    &-container--overview {
      height: 250px;
      width: 100%;
    }

    &-header {
      display: flex;
      justify-content: left;
      margin-bottom: 40px;
      align-items: center;

      & .badge-pill {
        padding: 16px 24px;
        font-weight: 500;
      }

      & .dashboard__title {
        margin-bottom: 0;
        margin-right: 15px;
        font-size: 27px;
      }
    }

    &-asside {
      padding-top: 40px;
      text-align: center;
    }
  }

  &-filters {
    background-color: $colorWhite;
    padding: rem(30px);
    margin-bottom: rem(20px);

    &__gyms {
      & .react-select__control {
        background-color: $softGray2;
        box-shadow: none !important;
        border: none;
        height: rem(50px);
      }
      & .react-select__indicators{
        margin-right: rem(15px);
      }
    }

    &__select-period {
      & .react-select__control {
        height: rem(50px);
        width: rem(170px);
        border: none;
        box-shadow: none !important;

        &:hover {
          border: none;
        }
      }
    }

    & .datepicker-button {
      font-size: rem(15px);
      width: fit-content;
    }

  }
}

@media screen and (max-width: 1140px) {
  .dashboard {
    &__container {
      grid-template-columns: repeat(1, minmax(400px, 1fr));

      &--graph {
        &-2cols {
          grid-template-columns: repeat(1, minmax(400px, 1fr));
          grid-row: auto;
          grid-template-areas: 'main' 'information';
        }

        &-3cols {
          grid-template-columns: repeat(1, minmax(330px, 1fr));
          grid-template-areas: 'main' 'information';
        }
      }
    }
  }
}
