.security--form {
  width: 25vw;
  .security--form__input {
    border-radius: 30px;
    padding: 10px 20px;
    height: 48px;
    margin: 7px 0;
  }
}


