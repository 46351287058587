[class^='icon-ic']:before,
[class*=' icon-ic']:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_eye:before {
  content: '\e90e';
}

.icon-ic_eyeclosed:before {
  content: '\e90f';
}
.icon-ic_eyeclosed:before {
  content: '\e902';
}
.icon-ic_eye:before {
  content: '\e903';
}
.icon-ic-arrow-down-circle {
  &:before {
    content: '\e900';
  }
  &--blue {
    color: $sweatBlue;
  }
  &--red {
    color: $colorSalmon;
  }
}
.icon-ic-arrow-up-circle {
  &:before {
    content: '\e901';
  }

  &--blue {
    color: $sweatBlue;
  }
  &--red {
    color: $colorSalmon;
  }
}

.icon-ic-cancel-circle:before {
  content: '\ea0d';
}

.icon-ic-search {
  &:before {
    content: '\e904';
  }
  &--blue {
    color: $sweatBlue;
  }
}
