.tab {
  &-headers {
    border: none;
    & .nav-item {
      border: none;
      border-right: 1px solid $colorGreyLetter;
      padding: 5px 20px;
      &:last-child {
        border-right: none;
      }
      & .nav-link {
        border: none;
        color: $colorGreyLetter !important;
        position: relative;
        padding: 0;
        font-size: rem(18px);
        font-weight: 400;
        &.active {
          color: $colorBlue10 !important;
          background-color: transparent !important;
          &::before {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $colorBlue10;
          }
        }
      }
    }
  }
}
