.header {
  &__settings {
    display: flex;
    align-items: flex-end;
    background-color: $colorWhite;
    &-dropdown {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      width: fit-content;
    }

    &-link {
      @include fonts($softGray, $fontAvenirRoman, 12, 12);
      display: block;
      letter-spacing: rem(1);
      padding: rem(7px) rem(20px);
      text-align: left;
      text-transform: uppercase;
      background-color: transparent;
      border-top: 0.5px solid $softGray7;
      &:hover {
        background-color: $softBlue4 !important;
        color: $softGray
      }
    }

    &-menu {
      background-color: $colorWhite;
      padding: 0;
      left: initial !important;
      right: 0 !important;
      min-width:rem(315px);
      transform: translate3d(0, 60px, 0px) !important;
      border-radius: rem(5px) !important;
      overflow: hidden;
      border: none;
    }

    &-toggle {
      @include fonts($softGray, $fontAvenirRoman, 16, 18);
      letter-spacing: rem(1);
      text-transform: uppercase;
      background-color: $colorWhite;
      display: flex;
      justify-content:space-between;
      align-items: center;
      padding: rem(9px)  rem(25px)!important;
      width: 100%;
      max-width: rem(305px);
      &:hover {
        @include fonts($softGray, $fontAvenirRoman, 16, 18);
        background-color: $softGray6;
      }
      & i{
        color: $softGray;
        font-size: rem(14px);
      }
    }
    &-header{
      display: flex;
      padding: rem(20px) ;
      & p{
        margin-bottom: 0;
        color: $softGray;
        font-size: rem(12px);
        &:first-child{
          text-transform: uppercase;
          font-weight: 700
        }
      }
    }
    &-footer{
      @include fonts($colorWhite, $fontAvenirRoman, 12, 12);
      padding: rem(24px) ;
      background-color: $darkBlue;
      & p{
        margin-bottom: 0;

        font-size: rem(12px);
        margin-bottom: rem(5px);
      }
    & a{
      display: flex;
      justify-content: center;
      align-items: center;
      color: $colorWhite;
      background-color: $sweatBlue;
      width: 100%;
      padding: rem(7px);
      border-radius: rem(5px);
      &:hover{
        background-color: $softBlue2;
        color: $darkBlue;
      }
     }
    }

  }
}
