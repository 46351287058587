$colorBlue10: #0a94c8;
$colorBlue: #00aced;
$colorRed: #bc2e2e;
$colorRed10: #e10009;
$colorGreen: #00b455;
$colorGrey: #f5f5f5;
$colorGreyLetter: #7e7e7e;
$softBlue: #e7f6ff;
$softBlue2: #9ad5fa;
$softBlue3: #d1eeff;
$softBlue4: #E6F6FF;
$darkBlue: #156597;
$darkBlue2: #09476D;
$sweatBlue: #4aabe7;
$softGray: #737373;
$softGray2: #f6f6f6;
$softGray3: #d2d2d2;
$softGray4: #cecece;
$softGray5: #bcbcbc;
$softGray6: #F1F5F9;
$softGray7: #B3B3B3;
$darkGray: #7b7b7b;
$colorBlack: #535353;
$colorSalmon: #ff464d;
$colorlightSalmon: #fff0f0;
$colorLightGreen: #f2ffa3;
$colordarkGreen: #8cc63f;
$colorWhite: #fff;

$fontBlack: 'Spartan-Black', sans-serif;
$fontBold: 'Spartan-Bold', sans-serif;
$fontExtraBold: 'Spartan-ExtraBold', sans-serif;
$fontExtraLight: 'Spartan-ExtraLight', sans-serif;
$fontLight: 'Spartan-Light', sans-serif;
$fontMedium: 'Spartan-Medium', sans-serif;
$fontRegular: 'Spartan-Regular', sans-serif;
$fontSemiBold: 'Spartan-SemiBold', sans-serif;
$fontThin: 'Spartan-Thin', sans-serif;
$fontAvenirBlack: 'Avenir-Black', sans-serif;
$fontAvenirBook: 'AvenirLTStd-Book', sans-serif;
$fontAvenirMedium: 'AvenirLTStd-Medium', sans-serif;
$fontAvenirRoman: 'AvenirLTStd-Roman', sans-serif;

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// $img   : '../../images/';
// $imgBg : '../../images/backgrounds/';
