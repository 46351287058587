@mixin fonts($color, $family, $size, $line-height: null, $weight: normal) {
  color: $color;
  font-family: $family;
  font-size: rem($size);
  font-weight: $weight;

  @if ($line-height) {
    line-height: rem($line-height);
  }
}

@function rem($px, $context: 16) {
  @if (unitless($px)) {
    $px: $px * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $px / $context * 1rem;
}

@mixin sideViewScrollBar($color, $border-radius, $width) {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
    border-radius: $border-radius;
  }
}

@mixin overflowEllipsisTextSingleLine {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}