.main--footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  ul {
    position: relative;
    left: -20%;
    li {
      a {
        background-color: #e8e8e8 !important;
        @include fonts(#bebebe, $fontAvenirBook, 19);
        line-height: rem(22);
        border-radius: 15px 15px 0 0 !important;
        padding: 12px 50px 5px;
        &.active {
          background-color: $colorBlue !important;
          color: white !important;
          &:hover{
          color: white !important;
          }
        }
        &:hover{
          color: $colorBlue !important;
          cursor: pointer;
        }
      }
    }
  }
}
