.income-report-form {
  &__multiselect {
    & .react-select__control {
      background-color: $softGray2;
      border: none;
    }
  }
  &__header {
    background-color: #fff;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 15px;
    &-title {
      color: $darkGray;
      font-weight: 600;
      flex: 0.6;
      & h1 {
        font-size: 24px;
        margin: 0;
        text-transform: uppercase;
      }
    }
    &-inputs {
      flex: 0.4;
      display: flex;
      gap: 10px;
      & .react-datepicker__input-container,
      .react-select-container {
        flex: 1;
      }
    }
  }

  &__body {
    background-color: #fff;
    padding: 50px 90px 55px 40px;
    border-radius: 5px;
    & .form-group {
      margin-bottom: 27px;
    }
    & .input-general__label {
      background-color: #fff;
    }
    &-title {
      color: $darkGray;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 25px;
      text-transform: uppercase;
    }
  }

  &__button {
    padding: 24px 32px;
    width: initial;
    background-color: $colorBlue !important;
    border: none;
    font-size: 14px;
    margin-top: 40px;
    text-transform: uppercase;
    min-width: 180px;
    color: #fff;
    &:hover {
      background-color: $colorBlue !important;
      color: #fff;
    }
    &:disabled {
      background-color: $softGray !important;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.income-report__nav{
  position: relative;
& .button__nav{
position: absolute;
left: 15px;
}
}

.income-reports__list {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
  &-title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 23px;
    color: $darkGray;
    margin-bottom: 50px;
  }
}

.income-report-statement {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 50px;
  &__header {
    &-actions {
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid $softGray;
    }
    &-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      & h1 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 23px;
        color: $darkGray;
        margin: 0;
      }
      & span {
        font-weight: 400;
        font-size: 14px;
        color: $darkGray;
        margin-left: 10px;
      }
    }
    &-info {
      display: flex;
      padding: 15px 0;
      justify-content: space-between;
      align-items: center;
      & p {
        margin-bottom: 0;
        margin-left: 30px;
        font-size: 22px;
        color: $darkGray;
        font-weight: 400;
      }
      & .badge {
        padding: 16px 24px;
        font-size: 14px;
        border-radius: 30px;
      }
      &-metadata{
        display: flex;
        flex-direction: column;
        text-align: end;
         & p {
          font-size: 12px;
          & span {
            font-weight: 600;
          }
         }
      }
    }
    &-buttons {
      display: flex;
    }
    &-button {
      border: none;
      text-transform: uppercase;
      padding: 12px 16px;
      background-color: $colorBlue !important;
      color: #fff;
      font-weight: 500;
      margin: 0 10px;
      display: flex;
      align-items: center;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }

      & img {
        margin-left: 5px;
      }
      &--ligth-blue{
        background-color: $softBlue3 !important;
        color: $darkBlue2;
        &:hover {
          color: $darkBlue2;
        }
      }
    }
  }
  &__body {
    &::-webkit-scrollbar {
      width: 2px;
      background-color: $softGray3;
    }

    /* Styles for the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      background-color: $darkGray;
    }
    & .general-table-list {
        margin-bottom: 0;
        & tbody {
          & tr {
            &:last-child {
              & td {
                color: $sweatBlue;
                font-weight: 600;
              }
            }
          }

      }
      & thead {
        text-transform: uppercase;
        font-size: 20px;
        & tr {
          & th {
            &:last-child {
              font-weight: 500;
              color: $sweatBlue;
              font-size: 14px;
            }
          }
        }
      }
      & tbody {
        & tr {
          font-size: 14px;
          & td{
            padding: 5px 20px;
          }
        }
        & tr:last-child {
          font-weight: 600;
          font-size: 16px;
          & td {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

.income-report__filters {
  & .datepicker-button {
    width: 290px;
  }
}
