.main--security {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .main--security__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    #main-logo {
      width: 300px;
    }

    .main--security__title {
      margin: 20px 0 5px;
      letter-spacing: 13px;
      color: $colorBlue;
      font-size: 1.3rem !important;
    }

    .hr-login {
      width: 50vw;
    }

    .main--security__mask {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.main--profile__fullname {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  p {
    @include fonts(grey, $fontAvenirBook, 15);
    display: block;
    margin-top: 7px;
    margin-bottom: 0;
  }

  button {
    @include fonts($colorBlue, $fontAvenirBook, 15);
    transition: all 0.35s linear;
    text-decoration: underline;
    &:hover {
      color: $colorBlue10;
      // text-decoration: none;
    }
  }
  a {
    color: $colorBlue;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
    text-decoration: underline;
    img{
      transform: rotate(180deg);
      margin-left: 5px;
    }
    &:hover {
      color: $colorBlue10;
      // text-decoration: none;
    }
  }
}

.logo-side {
  width: 180px !important;
}

.main--welcome-deafult {
  letter-spacing: 5px;
  @include fonts($colorBlue, $fontAvenirBook, 20);
  &.sub-item {
    letter-spacing: 2px;
    @include fonts($colorGreyLetter, $fontAvenirBook, 17);
  }
}

.main--menu-name {
  letter-spacing: 7px;
  color: white;
  font-size: 0.8rem;
  font-weight: 300;
}

.main--separator {
  background-color: white;
  width: 75%;
  &.blue {
    border-color: $colorBlue;
  }
}

.main--aside {
  background-color: $colorBlue;
  box-shadow: none !important;
  padding: 40px 0 10px;
  width: 250px;

  .main--menu-aside {
    margin-top: 35px;
    // overflow-y: scroll;

    .main--menu__item {
      @include fonts(white, $fontAvenirMedium, 18);
      display: block;
      margin-bottom: 15px;
      padding-left: 20px;
      padding-right: 20px;
      transition: all 0.35s linear;
      display: flex;
      align-items: center;
      & img {
        width: 20px;
        margin-right: 3px;
      }

      &.active {
        background-color: #009dd8;
      }

      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }

      i {
        font-size: 15px;
      }

      .fa {
        display: inline-block;
        font-size: rem(15);
        margin-right: 10px;
        text-align: center;
        width: 15px;
      }
    }

    .main--menu__subitem {
      color: white;
      display: block;
      margin-bottom: 10px;
      padding-left: 50px;
      padding-right: 20px;
      transition: all 0.35s linear;

      &.active {
        background-color: #009dd8;
      }

      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }

      &:last-child {
        margin-bottom: 25px;
      }

      .fa {
        display: inline-block;
        font-size: rem(14);
        margin-right: 5px;
        text-align: center;
        width: 15px;
      }
    }
  }

  .main--aside__close {
    background-color: transparent;
    border: none;
    display: none;
    left: 5px;
    position: absolute;
    top: 10px;

    svg {
      font-size: 20px;
    }
  }
}

.main--title {
  @include fonts($colorBlue, $fontAvenirBook, 20);
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
}

.main--subtitle {
  @include fonts($colorBlue, $fontAvenirBook, 17);
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
}

.main--graph__title {
  @include fonts($colorGreyLetter, $fontAvenirBook, 22);
  letter-spacing: 7px;
  text-align: center;
  text-transform: uppercase;
}

.main--form__label {
  @include fonts($colorBlue, $fontAvenirBook, 16);
  &.font-15 {
    font-size: rem(15) !important;
  }
}

.main--form__text-trough {
  position: absolute;
  width: 100%;
  top: 10px;
  border: 1px solid $colorBlue;
  opacity: 0.5;
  z-index: 1;
}

.main--form__invisible-input {
  position: relative;
  border: none !important;
  @include fonts($colorBlue, $fontAvenirBook, 22);
  text-align: center;
  width: 100px;
  background-color: $colorGrey;
  z-index: 2;
}

.main--form__select-hours {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 !important;
  padding: 2px 25px 2px 3px;
  color: grey;
  cursor: pointer;
  option {
    cursor: pointer;
    color: grey;
  }
}

.main--form__select {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0;
  @include fonts(grey, $fontAvenirBook, 18);
  cursor: pointer;
  display: inline;
  width: 150px;

  option {
    background-color: white;
    color: black;
  }
  &:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
  &.error {
    color: red;
  }
}

.main__schedule-submit {
  @include fonts($colorBlue, $fontAvenirBook, 15);
  line-height: rem(25);
  background-color: transparent;
  border-radius: 20px;
  border: $colorBlue 1px solid;
  position: absolute;
  margin-right: 20px;
  right: 0;
  top: -80px;
  width: 150px;
  padding: 5px 7px 3px !important;
  &:hover,
  &:focus:hover {
    background-color: $colorBlue !important;
    border: $colorBlue 1px solid;
    color: white !important;
  }
  &:focus {
    color: $colorBlue;
    border: $colorBlue 1px solid;
  }
}

.main--table-container-block {
  width: 100%;
  overflow: scroll;
  .table {
    td,
    .td {
      height: 40px !important;
    }
  }
}

.main--table {
  border: none;
  thead th,
  .thead .th {
    @include fonts(black, $fontAvenirMedium, 15);
    vertical-align: top;
    text-align: left;
    border-top: none;
    border-bottom: none;
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
  td,
  .td {
    @include fonts(black, $fontAvenirBook, 15);
    border-top: none;
    border-bottom: none;
    height: 40px !important;
    // padding: 0;
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
}

.table--users {
  th {
    &:first-child {
      border: none;
    }
    &:nth-child(2) {
      border: none;
    }
    &:nth-child(3) {
      border: none;
    }
  }
  td {
    @include fonts(black, $fontAvenirBook, 15);
    border-top: none;
    border-bottom: none;
    &:first-child {
      border-left: none;
      border-right: none;
    }
    &:nth-child(2) {
      border: none;
      padding: 10px 0;
      margin: 0;
    }
    &:nth-child(3) {
      border: none;
      padding: 10px 0;
      margin: 0;
    }
  }
}

.table--discounts {
  th {
    &:first-child {
      border: none;
    }
    &:nth-child(2) {
      border: none;
    }
  }
  td {
    @include fonts(black, $fontAvenirBook, 15);
    padding: 7px 10px !important;
    border-top: none;
    border-bottom: none;
    &:first-child {
      border-left: none;
      border-right: none;
    }
    &:nth-child(2) {
      border: none;
      padding: 10px 0;
      margin: 0;
    }
  }
}

.table--update_active {
  border: 3px solid $colorBlue;
  border-bottom: none;
}

.table--update_container {
  padding: 0 !important;
  margin: 0;
  .table--update_button-container {
    background-color: $colorBlue;
    height: 3px;
    text-align: center;
    button {
      @include fonts(white, $fontAvenirBook, 16);
      background-color: $colorBlue !important;
      border: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      position: relative;
      padding: 7px 15px;
      z-index: $zindex-dropdown;
      &:hover {
        background-color: $colorBlue10 !important;
      }
    }
  }
}

.table--update_input {
  border: none;
  background-color: transparent;
  &:focus {
    background-color: white;
    padding: 5px 5px;
    border-radius: 10px;
    border: 1px solid $colorBlue;
  }
}

.main--form_date {
  text-align: center;
  // width: 100px;
  border: none;
  background-color: transparent;
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  &:focus {
    background-color: white;
    padding: 5px 5px;
    border-radius: 10px;
    border: 1px solid $colorBlue;
  }
}

.main__holiday-add {
  @include fonts($colorBlue, $fontAvenirBook, 15);
  line-height: rem(25);
  background-color: transparent;
  border-radius: 20px;
  border: $colorBlue 1px solid;
  position: absolute;
  margin-right: 20px;
  right: 0;
  top: 95px;
  width: 150px;
  padding: 5px 7px 3px !important;
  &:hover {
    background-color: $colorBlue !important;
    border: $colorBlue 1px solid;
  }
}

.table {
  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    // border-bottom: 1px solid black;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    :last-child {
      border-right: 0;
    }
  }
}

.main--form__discount-border {
  border-top: 1px solid $colorBlue;
  border-bottom: 1px solid $colorBlue;
  hr {
    border-left: 1px solid $colorBlue;
    position: relative;
    top: 0;
    margin: 0;
    height: auto;
  }
}

.main--input-container--icon {
  position: relative;
  .main--sku__search {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    @include fonts($colorGreyLetter, $fontAvenirBook, 17);
    padding: 7px 30px 5px;
    width: 100%;
  }
  svg {
    position: absolute;
    top: 8px;
    left: 10px;
  }
}

.main--overflow-x-scroll {
  overflow-x: scroll;
}

.main--min-width-100 {
  min-width: 100%;
}

.main--section {
  background-color: #f2f7fb;
  min-height: 100vh;
  padding-bottom: 20px;
}
