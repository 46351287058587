* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  outline: 0;
  text-rendering: optimizeLegibility;
  text-decoration: none;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active  {
//   // box-shadow: 0 0 0 1000px #00acedcc inset;
//   // -webkit-text-fill-color: white;
// }

.invalid-feedback {
  @include fonts(red, $fontAvenirBook, 15);
  display: block !important;
  // font-weight: 800;
}

.popover {
  max-width: 100%;

  &.popover {
    .popover-body {
      padding: 0;
    }
  }
}

.rdrDateRangePickerWrapper {
  & .rdrDefinedRangesWrapper {
    display: none !important;
  }
}

.datepicker {
  &-select {
    color: $darkGray;
    border: none;
    width: 150px;
    margin-right: 10px;

    & p {
      margin-bottom: 0;
    }
  }

  &-button {
    color: $darkGray !important;
    border: none;
    border-radius: 0;
    background-color: $softGray2 !important;
    height: 50px;
    width: 345px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__text{
      display: flex;
      justify-content: space-around;
      flex: 1;
    }
    & p {
      margin-bottom: 0;
    }
    &:hover,
    &:active,
    &:focus {
      color: $darkGray !important;
      background-color: $softGray2 !important;
    }
  }
  &-separator {
    border-left: 1px solid $darkGray;
    width: 1px;
    margin: 0 15px;
  }
}
