%modal-default {
  .modal-content {
    background: $colorGrey;
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    display: flex;
    justify-content: center;
  }

  .modal-title {
    @include fonts($colorGreyLetter, $fontAvenirRoman, 18, 26);
    letter-spacing: rem(1.8);
    text-transform: uppercase;
  }
}

%modal-button {
  height: rem(48);
  width: rem(137);
  @include fonts(#fff, $fontAvenirRoman, 12, 19);
  letter-spacing: rem(1.2);
  text-transform: uppercase;
}

.ui-modal {
  &--change-password, &--forgot-password {
    max-width: rem(460);
    max-height: rem(661);

    @extend %modal-default;

    .modal-body {
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }


  &__title {
    @include fonts($colorGreyLetter, $fontAvenirRoman, 18, 26);
    letter-spacing: rem(1.8);
    text-transform: uppercase;
  }

  &__subtitle {
    @include fonts($colorBlue, $fontAvenirRoman, 12, 24);
    letter-spacing: rem(1.2);
    text-transform: uppercase;
  }

  &__button {
    @extend %modal-button;
    border: 1px solid $colorBlue;
    background: transparent;
    margin-right: rem(18);

    &--primary {
      @extend %modal-button;
      background: $colorBlue;
    }
  }
}